<template>
<div v-show="loaded">
  <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('IncomingLetter')}`">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <div class="flex">
            <vs-button color="primary" icon-pack="feather" icon="icon-edit" @click="$router.push({ name: 'incoming-letter-edit', params: { id: $route.params.id } })" class="mr-4">
              {{ $i18n.t('Edit') }}
            </vs-button>
            <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'incoming-letter' })">
              {{ $i18n.t('Back') }}
            </vs-button>
          </div>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2" v-for="(cell, i) in cells" :key="i">
            <div class="mt-6">
              <h6 class="font-bold">{{ cell.header }}</h6>
              <p>
                  <span v-if="cell.format">
                    {{ cell.format(item[cell.field]) }}
                  </span>
                <span v-else>
                    {{ item[cell.field] }}
                  </span>
              </p>
            </div>
          </div>
        </div>


      </vx-card>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "IncomingLetterDetail",
  data: () => ({
    loaded: false
  }),
  computed: {
    ...mapGetters({
      item: 'incomingLetter/showItem',
      items: 'incomingLetter/getItems'
    }),
    cells () {
      return [
        {
          header: this.$i18n.t('Receiver'),
          field: 'penerima'
        },
        {
          header: this.$i18n.t('DateOfReceipt'),
          field: 'tanggal_terima_display'
        },
        {
          header: this.$i18n.t('LetterNumber'),
          field: 'nomor_surat'
        },
        {
          header: this.$i18n.t('LetterDate'),
          field: 'tanggal_surat_display'
        },
        {
          header: this.$i18n.t('CompanyName'),
          field: 'nama_perusahaan'
        },
        {
          header: this.$i18n.t('Adress'),
          field: 'alamat'
        },
        {
          header: this.$i18n.t('ContactPerson'),
          field: 'cp'
        },
        {
          header: this.$i18n.t('PhoneFax'),
          field: 'telp_fax'
        },
        {
          header: this.$i18n.t('Subject'),
          field: 'perihal'
        },
        {
          header: this.$i18n.t('Page'),
          field: 'page'
        },
        {
          header: this.$i18n.t('Purpose'),
          field: 'tujuan_surat'
        },
        {
          header: this.$i18n.t('FileLocation'),
          field: 'lokasi_file'
        }
      ]
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, Data will be displayed.`
      })

    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    }
  },
  created () {
    this.loading();

    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('incomingLetter/detailItem', { id: this.id  }).then(() => {
      this.onLoaded();
    }).catch(err => {
      if (!err.response) {
        // network error
        err.response = {
          data: {
            message: 'Server error'
          }
        };
      }

      this.$vs.notify({
        title:'Opps something error',
        text: err.response.data.message,
        color:'danger'})
    });
  }
}
</script>

<style scoped>

</style>
